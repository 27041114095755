<template>
  <div class="xmsbxq">
    <div class="header">
      <div class="h1">{{ul[activeName].text}}</div>
      <div class="h2">政策扶持 携手共进 共创美好未来</div>
    </div>
    <div class="content">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="mbx">
        <el-breadcrumb-item >政策服务</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/xmsbxq' }">详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="xmsbxqcontent">
        <div class="tabs flex">
          <div class="flex-1 span" v-for="(li,index) in ul " @click="tabc(li,index)" :key="index"><span :class="{active:activeName==index}">{{li.text}}</span></div>
        </div>
        <div class="actor" v-if="activeName==0">
          <div class="news">
            <div class="title"><i class="yuan"></i>{{datas.title}}</div>
            <div class="biao">
              <span class="biaoqian" v-for="(bi,index) in datas.labels?datas.labels.split(','):''" :key="index">{{bi}}</span>
            </div>
            <div class="status">
              <span class="st1">{{datas.state}}</span>
              <span v-if="datas.isReq==1" @click.stop="gotoAdm(datas.adminUrl)"   style="float:right;color:#909399;cursor: pointer;">已申报</span>
              <el-button v-else type="primary" @click="goyuan(datas)" :disabled = disabled >申报入口</el-button>
              
              <p class="online" @click="gotoConsult" target="_blank"><span>在线咨询与预申请</span><img src="../../assets/serve-box/icon-1.png" title="在线咨询与预申请"  /></p>
            </div>
            <div class="di">
              <div class="">管理部门:{{datas.department}}</div>
              <div class="">申报起止时间:{{datas.startTime}}至{{datas.endTime}}</div>
            </div>
          </div>
          <el-descriptions title="" :column="2" border>
            <el-descriptions-item label="申报起止时间">{{ datas.startTime }}至{{datas.endTime}}</el-descriptions-item>
            <el-descriptions-item label="管理科室">{{datas.department}}</el-descriptions-item>
            <el-descriptions-item label="联系人">{{datas.contactUser}}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{datas.contactPhone}}</el-descriptions-item>
            <el-descriptions-item label="政策标题" span="2" >{{datas.policyTitle}}</el-descriptions-item>
            <el-descriptions-item label="申报对象和条件"  span="2" >{{datas.declarationCondition}}</el-descriptions-item>
            <el-descriptions-item label="申请书样例" span="2"  > <fujian :files="datas.example"></fujian></el-descriptions-item>
            <el-descriptions-item label="操作规程" span="2"  ><fujian :files="datas.process"></fujian></el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="actor" v-else-if="activeName==1">
          <div class="h1"> {{datas.title}}</div>
          <div class="h2"> 发布时间：{{datas.createTime}}     <span style="margin-left: 20px">来源：{{datas.source}}</span></div>
          <div class="ccontent" v-html="datas.content"></div>
          <fujian style="margin-top: 50px" :files="datas.files" v-if="datas.files" :showtitle="true"></fujian>
        </div>
        <div class="actor" v-else>
          <div class="h1"> {{datas.title}}</div>
          <div class="h2"> 发布时间：{{datas.createTime}}     <span style="margin-left: 20px">来源：{{datas.source}}</span></div>
          <div class="ccontent" v-html="datas.content"></div>
          <fujian style="margin-top: 50px" :files="datas.files" v-if="datas.files" :showtitle="true"></fujian>
        </div>
      </div>
    </div>
        <!--  -->
        <el-dialog title="项目申报" width="600px" append-to-body :visible.sync="dialogTableVisible">
      <avue-form :option="sqoption" v-model="userForm" @submit="submitSwitch" ref="userForm" @reset-change="resetFormSwitch"></avue-form>
    </el-dialog>
    <!--  -->
  </div>
</template>

<script>
import {policydetail, policymergeDetail, policyprojectdetail, policyunscrambledetail,saveReq} from "../../request/moudle/zcfw";
import {indexpublic} from "../../request/moudle";
import {filesub} from '../../utils/index'
import fujian from "../common/fujian.vue";
import Cookies from "js-cookie";
import config from '../../utils/config'
export default {
  name: "gonggaoxq",
  components: {fujian},
  data(){
    return {
      filesub:filesub,
      activeName:'0',
      datas:'',
      ul:[{text:'项目申报'},{text:'政策原文'},{text:'政策解答'}],
      arr:[],
      disabled: false,
      datasInfo:'',
      ///
      dialogTableVisible:false,
      sqoption:{
          column: [
          {
            label: '项目名称',
            prop: 'projectName',
            span: 24,
            type: 'input',
            readonly:true,
            rules: [{
              required: true,
              message: "请选择",
              trigger: "blur"
            }],
          },
          {
            label: '申报单位',
            prop: 'enterpriseName',
            span: 24,
            type: 'input',
            readonly:true,
            rules: [{
              required: true,
              message: "请选择",
              trigger: "blur"
            }],
          },
          {
            label: '联系人',
            prop: 'contactUser',
            span: 24,
            type: 'input',
            rules: [{
              required: true,
              message: "请选择",
              trigger: "blur"
            }],
          },
          {
            label: '电话',
            prop: 'contactPhone',
            span: 24,
            type: 'input',
            rules: [{
              required: true,
              message: "请选择",
              trigger: "blur"
            }],
          },
          {
            label: '上传资料',
            prop: 'files',
            type: 'upload',
            multiple: true,
            span: 24,
            limit: 10,
            dataType: 'string',
            rules: [{
              required: true,
              message: "请选择",
              trigger: "blur"
            }],
            propsHttp: {
              url: 'link',
              res: 'data'
            },
            headers: {
              'Authorization': `Basic ${Base64.encode(`pc:idsp-pc`)}`,
              'Tenant-Id': Cookies.get('Tenant-Id'),
              'Blade-Auth': 'bearer ' + Cookies.get('saber-access-token')
            },
            action: '/api/blade-resource/oss/endpoint/put-file'
          },
        ]
      },
      nowShenbao:{},
      userForm:{
        // policyProjectId:0,
        // enterpriseId:0,
        // projectName:'',
        // enterpriseName:'',
      },
    }
  },
  created() {
    this.activeName=this.$route.query.activeName || '0'
    this.loadlist();
    this.loadconfig();
  },
  methods:{
      //重置申请表单
      resetFormSwitch(){

const obj=this.nowShenbao;
this.userForm={

policyProjectId:obj.id,
enterpriseId:obj.enterpriseId,
projectName:obj.title,
enterpriseName:obj.enterpriseName,

}
},
    gotoAdm(src){
      window.open(config.adminhref+`#${src}`, '_blank');
    },
    submitSwitch(form,done){
      // alert(JSON.stringify(this.userForm));
      saveReq(this.userForm).then(res=>{
        if(res.code==200){
          this.$message.success('申请成功');
          this.$refs.userForm.resetForm();
          done();
          this.dialogTableVisible=false;
          this.loadlist();
          
         
        }
      }).catch(err=>{
        done();
      }
      );
    },
    //打开对话框
    openDia(obj){
      this.dialogTableVisible=true;
      
      console.log('数据',obj);
      this.userForm={
      
        policyProjectId:obj.id,
        enterpriseId:obj.enterpriseId,
        projectName:obj.title,
        enterpriseName:obj.enterpriseName,
    
      }
    },
    loadconfig(){
        this.$api.index.indexpublic().then((res)=>{
            this.datasInfo=res.data;
        })
    },
    tabc(li,index){
      this.activeName=index
      this.tabs()
    },
    goyuan(obj){
      // this.activeName=0;
      // window.open(this.datas.link, '_blank');
      
      this.dialogTableVisible=true;
      this.nowShenbao=obj;
      console.log('数据',obj);
      this.userForm={
      
        policyProjectId:obj.id,
        enterpriseId:obj.enterpriseId,
        projectName:obj.title,
        enterpriseName:obj.enterpriseName,
    
      }
    },
    tabs(){
      this.loadlist()
    },
    loadlist(){
      this.$api.zcfw.policymergeDetail({
        policyId:this.$route.query.id
      }).then((res)=>{
        if( this.activeName==1){
          this.datas=res.data.policy
        }else if(this.activeName==2){
          this.datas=res.data.policyUnscramble
        }else {
          this.datas=res.data.policyProject;
          if(this.datas.link){
            this.disabled = false;
          }else{
            this.disabled = true;
          }
        }

      })
    },
    gotoConsult(){
      window.open(this.datasInfo.consultUrl)
    }
    // loadlist1(){
    //   this.$api.zcfw.policyunscrambledetail({
    //     id:this.$route.query.id
    //   }).then((res)=>{
    //     this.datas=res.data
    //   })
    // },
    // loadlist2(){
    //   this.$api.zcfw.policyprojectdetail({
    //     id:this.$route.query.id
    //   }).then((res)=>{
    //     this.datas=res.data
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
   /deep/ .ccontent{
      display: inline-block;
      font-size: 18px!important;
      line-height: 35px!important;
    }
    .fujian{
      margin-top: 50px;
      .as{
        line-height: 25px;
        color:  #409EFF;
      }
    }
  .xmsbxq{
     background: #F5F5F5;
    .header{
      height: 120px;
      background: url("../../assets/banner/xmsb.png") no-repeat;
      background-size: 100% 100%;
      padding-top: 90px;
      text-align: center;
      color: #fff;
      .h1{
        font-size: 42px;
        font-weight: bolder;
      }
      .h2{
        font-size: 22px;
        line-height: 80px;
      }
    }
    .online {
      > span {
        color: #606266;
        font-size: 12px;
        margin-right: 4px;
      }
      > img{
        margin-top:4px;
        cursor: pointer;
      }
    }
    .content{
      width: 1200px;
      margin: 20px auto;
      .xmsbxqcontent{
          background: #fff;
          padding: 20px;
          margin-top: 20px;
        .tabs {
          border-bottom: 1px #D7D7D7 solid;
          text-align: center;
            span{
              font-weight: 550;
              padding:10px 22px;
              line-height: 39px;
              cursor: pointer;
            }
          .active{
            background:#0078F2;
            color: #fff;
          }
        }
        .actor{
          padding: 20px;
          .h1{
            font-weight: bolder;
            font-size: 22px;
            text-align: center;
          }
          .h2{
            text-align: center;
            line-height: 65px;
            font-size: 14px;
            color: #999999;
          }
          p{
            padding: 10px 0;
          }
          .news{
            background:linear-gradient(0deg,#FFFFFF,#EEF1F5);
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin-bottom: 55px;
            padding: 20px;
            color: #3B3E45;
            .title{
              font-size: 18px;
              margin-bottom: 10px;
            }
            .biao{
              padding-left: 27px;
              color: #fff;
              margin: 20px 0 0px 0;
            }
            .status{
              text-align: right;
              margin-right: 30px;
              font-size: 16px;
              color: red;
            }
            .st1{
              margin-right: 40px;
            }
            .biaoqian{
              background:linear-gradient(0deg,#0078F2,#00BAFF);
              padding: 2px 8px;
              margin-right: 10px;
            }
            .yuan{
              display: inline-block;
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background: #A5ACB9;
              margin-right: 15px;
            }
            .di{
              font-size: 14px;
              padding-left: 27px;
              color: #6D6D6D;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
</style>